import React from "react";

const BarIcon = () => {
  return (
    <svg
      width="19px"
      height="18px"
      viewBox="0 0 19 18"
      fill="current"
      stroke="none"
    >
      <g>
        <path
          d="M0.999 0L18.001 0Q18.0994 0 18.1959 0.0191955Q18.2924 0.038391 18.3833 0.0760443Q18.4742 0.113698 18.556 0.168362Q18.6378 0.223026 18.7074 0.2926Q18.777 0.362175 18.8316 0.443985Q18.8863 0.525796 18.924 0.616699Q18.9616 0.707602 18.9808 0.804105Q19 0.900607 19 0.999L19 1.001Q19 1.09939 18.9808 1.1959Q18.9616 1.2924 18.924 1.3833Q18.8863 1.4742 18.8316 1.55601Q18.777 1.63783 18.7074 1.7074Q18.6378 1.77697 18.556 1.83164Q18.4742 1.8863 18.3833 1.92396Q18.2924 1.96161 18.1959 1.9808Q18.0994 2 18.001 2L0.999 2Q0.900607 2 0.804105 1.9808Q0.707602 1.96161 0.616699 1.92396Q0.525796 1.8863 0.443985 1.83164Q0.362175 1.77697 0.2926 1.7074Q0.223026 1.63783 0.168362 1.55601Q0.113698 1.4742 0.0760443 1.3833Q0.038391 1.2924 0.0191955 1.1959Q0 1.09939 0 1.001L0 0.999Q0 0.900607 0.0191955 0.804105Q0.038391 0.707602 0.0760443 0.616699Q0.113698 0.525796 0.168362 0.443985Q0.223026 0.362175 0.2926 0.2926Q0.362175 0.223026 0.443985 0.168362Q0.525796 0.113698 0.616699 0.0760443Q0.707602 0.038391 0.804105 0.0191955Q0.900607 0 0.999 0Z"
          fill="current"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M0.999 0L7.001 0Q7.09939 0 7.19589 0.0191955Q7.2924 0.038391 7.3833 0.0760443Q7.4742 0.113698 7.55601 0.168362Q7.63782 0.223026 7.7074 0.2926Q7.77697 0.362175 7.83164 0.443985Q7.8863 0.525796 7.92395 0.616699Q7.96161 0.707602 7.9808 0.804105Q8 0.900607 8 0.999L8 1.001Q8 1.09939 7.9808 1.1959Q7.96161 1.2924 7.92395 1.3833Q7.8863 1.4742 7.83164 1.55601Q7.77697 1.63783 7.7074 1.7074Q7.63782 1.77697 7.55601 1.83164Q7.4742 1.8863 7.3833 1.92396Q7.2924 1.96161 7.19589 1.9808Q7.09939 2 7.001 2L0.999 2Q0.900607 2 0.804105 1.9808Q0.707602 1.96161 0.616699 1.92396Q0.525796 1.8863 0.443985 1.83164Q0.362175 1.77697 0.2926 1.7074Q0.223026 1.63783 0.168362 1.55601Q0.113698 1.4742 0.0760443 1.3833Q0.038391 1.2924 0.0191955 1.1959Q0 1.09939 0 1.001L0 0.999Q0 0.900607 0.0191955 0.804105Q0.038391 0.707602 0.0760443 0.616699Q0.113698 0.525796 0.168362 0.443985Q0.223026 0.362175 0.2926 0.2926Q0.362175 0.223026 0.443985 0.168362Q0.525796 0.113698 0.616699 0.0760443Q0.707602 0.038391 0.804105 0.0191955Q0.900607 0 0.999 0Z"
          transform="translate(6 8)"
          fill="current"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M0.999 0L18.001 0Q18.0994 0 18.1959 0.0191955Q18.2924 0.038391 18.3833 0.0760443Q18.4742 0.113698 18.556 0.168362Q18.6378 0.223026 18.7074 0.2926Q18.777 0.362175 18.8316 0.443985Q18.8863 0.525796 18.924 0.616699Q18.9616 0.707602 18.9808 0.804105Q19 0.900607 19 0.999L19 1.001Q19 1.09939 18.9808 1.1959Q18.9616 1.2924 18.924 1.3833Q18.8863 1.4742 18.8316 1.55601Q18.777 1.63783 18.7074 1.7074Q18.6378 1.77697 18.556 1.83164Q18.4742 1.8863 18.3833 1.92396Q18.2924 1.96161 18.1959 1.9808Q18.0994 2 18.001 2L0.999 2Q0.900607 2 0.804105 1.9808Q0.707602 1.96161 0.616699 1.92396Q0.525796 1.8863 0.443985 1.83164Q0.362175 1.77697 0.2926 1.7074Q0.223026 1.63783 0.168362 1.55601Q0.113698 1.4742 0.0760443 1.3833Q0.038391 1.2924 0.0191955 1.1959Q0 1.09939 0 1.001L0 0.999Q0 0.900607 0.0191955 0.804105Q0.038391 0.707602 0.0760443 0.616699Q0.113698 0.525796 0.168362 0.443985Q0.223026 0.362175 0.2926 0.2926Q0.362175 0.223026 0.443985 0.168362Q0.525796 0.113698 0.616699 0.0760443Q0.707602 0.038391 0.804105 0.0191955Q0.900607 0 0.999 0Z"
          transform="translate(0 16)"
          fill="current"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  );
};
export default BarIcon;
